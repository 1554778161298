import React, { useState } from 'react'
import { Link } from 'gatsby'
import { H2, Paragraph2, H3 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import Layout from '../components/layout'
import GeneratorWrapper from '../components/GeneratorWrapper'
import Faq from '../components/faq'
import SEO from '../components/seo'
import Steps from '../components/steps'
import Hero from '../components/hero'
import CTA from '../components/RegisterCTA'
import Pricing from '../components/Pricing'
import Emoji from '../components/Emoji'
import PricingBanner from '../components/PricingBanner'
import { FiExternalLink, FiArrowDown } from 'react-icons/fi'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { isMobile } from 'react-device-detect'
import SearchLink from '../components/SearchLink'

const IndexPage = () => {
  const [css] = useStyletron()
  const [focusElement, setFocusElement] = useState('')

  const centerText = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const actionCard = css({
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    // padding: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '15px',
    margin: '15px',
    minWidth: '220px',
    textDecoration: 'none',
    backgroundColor: '#fff',
  })

  return (
    <Layout hero={<Hero />} page="home">
      <SEO
        title="Create WhatsApp links"
        hreflang={[
          { rel: 'alternate', hrefLang: 'en', href: 'https://create.wa.link' },
          { rel: 'alternate', hrefLang: 'es', href: 'https://crear.wa.link' },
          { rel: 'alternate', hrefLang: 'pt', href: 'https://criar.wa.link' },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link',
          },
        ]}
      />
      {/* From here */}
      <div
        className={css({
          marginTop: '3rem',
          paddingBottom: '3rem',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
            marginBottom: '2rem',
          })}
        >
          What would you like to do today?
        </h2>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: 'rgb(238, 238, 238);',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            borderRadius: '15px',
          })}
        >
          <ActionPopover content="Create WhatsApp links with custom message, for free">
            <a
              href="/"
              style={{}}
              onClick={e => {
                e.preventDefault()
                scrollTo('#create-link')
              }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'😁'} /> Create a free wa.link
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover
            content={
              <>
                For <strong>USD $6/year</strong> get a branded wa.link with
                updatable info, analytics, search and more...
              </>
            }
          >
            <a
              href="#premium"
              style={{ textDecoration: 'none' }}
              className={actionCard}
              onClick={e => {
                e.preventDefault()
                scrollTo('#premium')
              }}
            >
              <p>
                <Emoji symbol={'👑'} /> Get a branded wa.link
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover content="Open WhatsApp chats without saving contacts">
            <a
              href="https://open.wa.link"
              style={{ textDecoration: 'none' }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'💬'} /> Open WhatsApp chats
                <span style={{ marginLeft: '5px' }}>
                  <FiExternalLink size="16" />
                </span>
              </p>
            </a>
          </ActionPopover>
        </div>
      </div>
      <div
        className={css({
          // margin: '4rem 0',
          margin: '3rem 0 4rem 0',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
          })}
        >
          Why create WhatsApp links with Walink?
        </h2>
        <div className={centerText}>
          <Paragraph2>
            To open a WhatsApp chat, you usually need to know the user’s phone
            number, save it on your device and search for it to start the
            conversation. A WhatsApp link simplifies those steps in 1 single
            click that opens the chat without saving phone numbers, this is what
            it’s called <strong>"click to chat”.</strong>
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            If you would like to create WhatsApp links for people to contact you
            instantly, you are in the right place. Walink lets you generate
            WhatsApp links for free with <strong>wa.link domain name</strong>{' '}
            and <strong>QR code</strong> in less than 1 minute, no registration
            required.
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            On top of that, you can have official links for your business by
            subscribing to Walink Premium to create branded WhatsApp links like
            <strong> wa.link/MyBusiness</strong> that will lead more customers
            to your chat, have click analytics, updatable information,
            multiagent links, form links and more.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          margin: '50px auto',
          background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
          minHeight: '310px',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          borderRadius: '10px',
        })}
      >
        <Steps changeFocus={setFocusElement} />
      </div>
      <div
        id="create-link"
        className={css({
          paddingTop: '10px',
        })}
      >
        <H2 className={css({ textAlign: 'center' })}>
          Create your free link here
        </H2>
        <GeneratorWrapper focusElement={focusElement} />
      </div>

      <div
        className={css({ marginTop: '3rem', marginBottom: '1rem' })}
        id="premium"
      >
        <H3 className={css({ textAlign: 'center' })}>
          Find a wa.link for your brand
        </H3>
        <SearchLink showPremium={true} />
      </div>
      <div className={css({ marginTop: '60px', marginBottom: '60px' })}>
        <Pricing />
        <PricingBanner />
      </div>
      <div className={css({ marginTop: '50px' })}>
        <div className={css({ margin: '20px' })}>
          <H2>FAQs - Frequently Asked Questions:</H2>
        </div>
        <Faq />
        <CTA
          title="Do you want a branded link?"
          description={
            <>
              With{' '}
              <Link
                to="/premium"
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Walink Premium
              </Link>{' '}
              you can create, track and modify your custom WhatsApp links.{' '}
              <strong>(wa.link/YourBrand)</strong>
            </>
          }
          currentPage="Home"
        />
      </div>
    </Layout>
  )
}

function ActionPopover({ children, content }) {
  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}

          <StatefulPopover
            showArrow
            content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.click}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '250px',
                  textAlign: 'center',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <span
              style={{
                verticalAlign: 'middle',
                marginRight: '15px',
                fontStyle: 'italic',
              }}
            >
              <strong>i</strong>
            </span>
          </StatefulPopover>
        </div>
      ) : (
        <StatefulPopover
          showArrow
          content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
          accessibilityType={'tooltip'}
          triggerType={TRIGGER_TYPE.hover}
          placement={PLACEMENT.bottom}
          overrides={{
            Body: {
              style: {
                width: '250px',
                textAlign: 'center',
                marginTop: '10px',
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            Inner: {
              style: {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
          }}
        >
          {children}
        </StatefulPopover>
      )}
    </>
  )
}

export default IndexPage
